import { AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TContact } from '@/_types/contact.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import ContactHelper from '@/_helpers/contact.helper';
import { TEditContactActionParams } from '@/_modules/promo/store/promo-page.store';
import { ContactsFilterType } from '@/_modules/contacts/types/contacts-filter.type';
import { BASE_URL_STATISTICS } from '@/_modules/statistics/api/statistics.api';

export type TGetContactParams = {
  eventId: number;
  contactId: number;
}

export type TCallCurrentUserContactParams = {
  eventId: number;
}

export type TDeleteContactParams = {
  eventId: number;
  contactId: number;
}

export type TGetEventContactsPageParams = {
  eventId: number;
  limit?: number;
  offset?: number;
  search?: string;
  tags?: number[];
  type?: ContactsFilterType;
  externalId?: string;
};

export type TAddContactByCodeParams = {
  eventId: number;
  externalId: string;
  code: string;
};

export type TEditContactRequestParams = {
  event_id: number;
  name: string;
  surname?: string;
  email?: string;
  photo_url?: string;
  country?: string;
  city?: string;
  company_name?: string;
  company_website?: string;
  company_position?: string;
  company_description?: string;
  tags?: number[];
  phone?: string;
  lang?: string;
}

export type TPutContactFileParams = {
  url: string;
  filename: string;
  event_id: number;
}

export type TDeleteContactFileParams = {
  file_id: number;
  event_id: number;
}

export class ContactsApi extends BaseApi {

  public async getContact(params: TGetContactParams, cancelToken?: CancelToken): Promise<TContact> {
    const { eventId, contactId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/${contactId}/info`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return ContactHelper.responseToContactConverter(response.data);
  }

  public async callCurrentUserContact(params: TCallCurrentUserContactParams, cancelToken?: CancelToken): Promise<TContact> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/info`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return ContactHelper.responseToContactConverter(response.data);
  }

  /**
   * @deprecated The method should not be used
   */
  public async getAllEventContacts(params: TGetEventContactsPageParams, cancelToken?: CancelToken): Promise<TContact[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/list/all`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List.map(item => {
      return ContactHelper.responseToContactConverter(item);
    });
  }

  public async getEventContactsPage(params: TGetEventContactsPageParams, cancelToken?: CancelToken): Promise<TApiListResponse<TContact>> {
    const { eventId, limit, offset, search, tags, type, externalId } = params;
    let url: string;
    let requestParams: any = {
      limit,
      offset,
      search: search || undefined,
      tags: (tags && tags.length) ? tags : undefined,
    };
    switch (type) {
      case ContactsFilterType.BOOTH_VIEWERS:
        url = BASE_URL_STATISTICS + `/event/${eventId}/stat/promopage/${externalId}/viewers`;
        requestParams = null;
        break;
      case ContactsFilterType.MY:
        url = `/event/${eventId}/contact/list`;
        break;
      default:
        url = `/event/${eventId}/contact/list/all`;
    }

    const config: AxiosRequestConfig = {
      url,
      method: 'GET',
      params: requestParams,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return Object.assign(response.data, {
      List: response.data.List.map(item => ContactHelper.responseToContactConverter(item)),
    });
  }

  // TODO: will we need this in the future? Not calling it anywhere currently.
  public async getOwnEventContacts(params: TGetEventContactsPageParams, cancelToken?: CancelToken): Promise<TContact[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List.map(item => {
      return ContactHelper.responseToContactConverter(item);
    });
  }

  public async addContactByCode(params: TAddContactByCodeParams, cancelToken?: CancelToken): Promise<boolean> {
    const { eventId, externalId, code } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/promopage/${externalId}/code`,
      method: 'PUT',
      params: {code: code},
      cancelToken,
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;

  }

  public async addFavContact(params: { eventId: number; contactId: number }): Promise<void> {
    const { eventId, contactId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/${contactId}/fav`,
      method: 'PUT',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async removeFavContact(params: { eventId: number; contactId: number }): Promise<void> {
    const { eventId, contactId } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/${contactId}/fav`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async editContact(params: TEditContactActionParams): Promise<boolean> {

    const { event_id } = params;
    const requestParams: TEditContactRequestParams = {
      event_id: params.event_id,
      name: params.name,
      surname: params.surname,
      email: params.email,
      photo_url: params.photo_url,
      country: params.country,
      city: params.city,
      company_name: params.company_name,
      company_website: params.company_website,
      company_position: params.company_position,
      company_description: params.company_description,
      tags: params.tags.map(x => x.id),
      phone: params.phone || null,
      lang: params.lang
    };

    delete requestParams.event_id;

    const config: AxiosRequestConfig = {
      url: `event/${event_id}/contact/info`,
      method: 'PATCH',
      params: requestParams,
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;

  }

  public async putContactFile(params: TPutContactFileParams): Promise<boolean> {
    const { event_id } = params;
    delete params.event_id;
    const config: AxiosRequestConfig = {
      url: `event/${event_id}/contact/file`,
      params: params,
      method: 'PUT'
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

  public async deleteContactFile(params: TDeleteContactFileParams): Promise<boolean> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/contact/file/${params.file_id}`,
      method: 'DELETE'
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

  public async deleteContact(params: TDeleteContactParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.eventId}/contact/${params.contactId}`,
      method: 'DELETE'
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

}

const contactsApi = new ContactsApi();
export default contactsApi;
