import { TNote } from '@/_types/store/note.type';
import { TContact } from '@/_types/contact.type';

export enum TabNames {
  NONE = '_nothing_',
  NOTIFICATIONS = 'notifications',
  CORRESPONDENCE = 'correspondence',
  SCHEDULE = 'SCHEDULE',
  CONTACT = 'contact',
}

export type TLastContacts = {
  contact: TContact;
  timestamp: number;
}

export type TSideBarRightStoreState = {
  isOpen: boolean;
  isSideBarRightComponentPresent: boolean;
  activeTabName: TabNames;
  selectedContactNotes: TNote[];
  isUserSettingsOpen: boolean;
  isQRCodePopupVisible: boolean;
  contactId: number;
  lastContactsMap: { [id: string]: TLastContacts };
}
