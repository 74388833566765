import { render, staticRenderFns } from "./helpcrunch-icon.html?vue&type=template&id=40274401&scoped=true&"
import script from "./helpcrunch-icon.vue?vue&type=script&lang=ts&"
export * from "./helpcrunch-icon.vue?vue&type=script&lang=ts&"
import style0 from "./helpcrunch-icon.scss?vue&type=style&index=0&id=40274401&scoped=true&lang=scss&"
import style1 from "./helpcrunch-icon.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40274401",
  null
  
)

export default component.exports