import {ActionContext, Module} from 'vuex';
import {AxiosResponse} from 'axios';
import {TAppStoreState} from '@/_types/store/app-store-state.type';
import {TDateSchedule, TEventDay, TMeeting} from '@/_types/meeting/meeting.type';
import meetingsApi, {
  TCancelMeetingParams,
  TConfirmMeetingParams, TGetManagedMeetingsParams,
  TRequestMeetingParams
} from '@/_api/meetings/meetings.api';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import { TMeetingsStoreState } from '@/_modules/meetings/types/meetings-store-state.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';

export type TUpdateMeetingContactFavoriteParams = {
  isFavorite: boolean;
  contactId: number;
}

const meetingsStore: Module<TMeetingsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    lastError: null,
    meetingsByUserId: {},
    loadingPromiseByUserId: {},
    isMeetingTooEarlyPopupVisible: false,
    isMeetingCancelPopupVisible: false,
    isMeetingDeclinePopupVisible: false,
    isMeetingCancel: false, // TODO: better naming
    isMeetingDecline: false, // TODO: better naming
    selectedDate: {
      meetings: {
        badgeNotification: false,
        date: null,
        dayNumber: '',
        month: null,
        monthName: '',
        week: null,
      },
      contactSchedule: {
        badgeNotification: false,
        date: null,
        dayNumber: '',
        month: null,
        monthName: '',
        week: null,
      },
      sidebarContactSchedule: {
        badgeNotification: false,
        date: null,
        dayNumber: '',
        month: null,
        monthName: '',
        week: null,
      },
      sidebarSchedule: {
        month: null,
        monthName: '',
        week: null,
        date: null,
        dateKey: '',
        dayNumber: null,
        items: [],
      }
    },
    managedMeetingsPage: null,
  },
  getters: {

    getMeetingsByUserId: (state: TMeetingsStoreState) => (userId: number): TMeeting[] => {
      return state.meetingsByUserId[userId] || [];
    },
    getIsLoadingByUserId: (state: TMeetingsStoreState) => (userId: number): boolean => {
      return !!state.loadingPromiseByUserId[userId];
    },
    getMeetingsSelectedDate: (state: TMeetingsStoreState): TEventDay => {
      return state.selectedDate.meetings;
    },
    getContactSelectedDate: (state: TMeetingsStoreState): TEventDay => {
      return state.selectedDate.contactSchedule;
    },
    getSidebarContactSelectedDate: (state: TMeetingsStoreState): TEventDay => {
      return state.selectedDate.sidebarContactSchedule;
    },
    getSidebarScheduleSelectedDate: (state: TMeetingsStoreState): TDateSchedule => {
      return state.selectedDate.sidebarSchedule;
    },
    isMeetingTooEarlyPopupVisible: (state: TMeetingsStoreState): boolean => {
      return state.isMeetingTooEarlyPopupVisible;
    },
    isMeetingCancelPopupVisible: (state: TMeetingsStoreState): boolean => {
      return state.isMeetingCancelPopupVisible;
    },
    isMeetingDeclinePopupVisible: (state: TMeetingsStoreState): boolean => {
      return state.isMeetingDeclinePopupVisible;
    },
    // TODO: better naming
    isMeetingCancel: (state: TMeetingsStoreState): boolean => {
      return state.isMeetingCancel;
    },
    // TODO: better naming
    isMeetingDecline: (state: TMeetingsStoreState): boolean => {
      return state.isMeetingDecline;
    },
    getManagedMeetingsPage: (state: TMeetingsStoreState): TApiListResponse<TMeeting> => {
      return state.managedMeetingsPage;
    }
  },
  actions: {

    reset: ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>): void => {
      commit('reset');
    },

    setEventId: async ({ commit, state }: ActionContext<TMeetingsStoreState, TAppStoreState>, eventId: number): Promise<void> => {
      if (state.eventId === eventId) {
        return;
      }
      commit('reset');
      commit('setEventId', eventId);
    },

    requestUserMeetings: async ({ commit, state }: ActionContext<TMeetingsStoreState, TAppStoreState>, params: { userId: number; force?: boolean }): Promise<TMeeting[]> => {
      const { userId, force } = params;
      const existingMeetings = state.meetingsByUserId[userId];

      if (!force && existingMeetings) {
        return existingMeetings;
      }

      if (state.loadingPromiseByUserId[userId]) {
        /* already loading */
        return await state.loadingPromiseByUserId[userId];
      }

      let meetings: TMeeting[] = null;
      commit('setLastError', null);
      try {
        const promise = meetingsApi.getMeetings({ event_id: state.eventId, user_id: userId });
        commit('setLoadingPromise', { userId, promise: promise });
        meetings = await promise;
        commit('setMeetings', { userId, meetings });
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setLoadingPromise', { userId, promise: null });
      }

      return meetings;
    },

    requestMeeting: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, params: TRequestMeetingParams): Promise<AxiosResponse> => {
      commit('setLastError', null);
      try {
        const response = await meetingsApi.requestMeeting(params);
        commit('storeAMeeting', response.data);
        return response;
      } catch(error) {
        commit('setLastError', error);
        return error.data; // error is instanceof APIError, must have the .data field
      }
    },

    storeAMeeting: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, meeting: TMeeting): Promise<void> => {
      commit('storeAMeeting', meeting);
    },

    confirmMeeting: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, params: TConfirmMeetingParams): Promise<AxiosResponse> => {
      commit('setLastError', null);
      try {
        const response = await meetingsApi.confirmMeeting(params);
        if (response && response.status === 202) {
          commit('setConfirmedMeetings', params.meeting_id);
        }
        return response;
      } catch(error) {
        commit('setLastError', error);
        return error.data; // error is instanceof APIError, must have the .data field
      }
    },

    cancelMeeting: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, params: TCancelMeetingParams): Promise<AxiosResponse> => {
      commit('setLastError', null);
      try {
        const response = await meetingsApi.cancelMeeting(params);
        if (response && response.status === 202) {
          commit('setStatusCanceledByMeetingId', params.meeting_id);
        }
        return response;
      } catch(error) {
        commit('setLastError', error);
        return error.data; // error is instanceof APIError, must have the .data field
      }
    },

    setMeetingCanceledByMeetingId: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, meetingId: number): Promise<void> => {
      commit('setStatusCanceledByMeetingId', meetingId);
    },

    setMeetingConfirmedByMeetingId: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, meetingId: number): Promise<void> => {
      commit('setStatusConfirmedByMeetingId', meetingId);
    },

    meetingsScheduleDate: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, selectedDate: TEventDay): Promise<void> => {
      commit('setMeetingsScheduleDate', selectedDate);
    },
    contactScheduleDate: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, selectedDate: TEventDay): Promise<void> => {
      commit('setContactScheduleDate', selectedDate);
    },
    sideBarContactScheduleDate: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, selectedDate: TEventDay): Promise<void> => {
      commit('setSidebarContactScheduleDate', selectedDate);
    },
    sidebarScheduleDate: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, selectedDate: TDateSchedule): Promise<void> => {
      commit('setSidebarScheduleDate', selectedDate);
    },
    updateContactFavorite: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, payload: TUpdateMeetingContactFavoriteParams): Promise<void> => {
      commit('updateContactFavorite', payload);
    },
    setMeetingTooEarlyPopupVisible: ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, value: boolean): void => {
      commit('setMeetingTooEarlyPopupVisible', value);
    },
    setMeetingCancelPopupVisible: ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, value: boolean): void => {
      commit('setMeetingCancelPopupVisible', value);
    },
    setMeetingDeclinePopupVisible: ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, value: boolean): void => {
      commit('setMeetingDeclinePopupVisible', value);
    },
    setMeetingCancel: ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, value: boolean): void => {
      commit('setMeetingCancel', value);
    },
    setMeetingDecline: ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, value: boolean): void => {
      commit('setMeetingDecline', value);
    },
    requestManagedMeetingsPage: async ({ commit }: ActionContext<TMeetingsStoreState, TAppStoreState>, params: TGetManagedMeetingsParams): Promise<void> => {
      let meetings: TApiListResponse<TMeeting> = null;

      try {
        meetings = await meetingsApi.getManagedMeetings(params);
      } catch (error) {
        commit('setLastError', error);
      }

      commit('setManagedMeetingsPage', meetings);

    },
  },
  mutations: {

    reset(state: TMeetingsStoreState): void {
      state.eventId = null;
      state.lastError = null;
      state.meetingsByUserId = {};
      state.loadingPromiseByUserId = {};
    },

    setEventId(state: TMeetingsStoreState, eventId: number): void {
      state.eventId = eventId;
    },

    setLastError(state: TMeetingsStoreState, error: Error): void {
      state.lastError = error;
    },

    setLoadingPromise(state: TMeetingsStoreState, params: { userId: number; promise: Promise<TMeeting[]> }): void {
      state.loadingPromiseByUserId[params.userId] = params.promise;
      state.loadingPromiseByUserId = Object.assign({}, state.loadingPromiseByUserId);
    },

    setMeetings(state: TMeetingsStoreState, params: { userId: number; meetings: TMeeting[] }): void {
      state.meetingsByUserId[params.userId] = params.meetings;
      state.meetingsByUserId = Object.assign({}, state.meetingsByUserId);
    },

    setConfirmedMeetings(state: TMeetingsStoreState, meetingId: number): void {

      Object.values(state.meetingsByUserId).forEach((meetings: TMeeting[]) => {
        const meeting = meetings.find(item => item.id === meetingId);
        if (meeting) {
          meeting.status = MeetingStatus.Confirmed;
        }
      });
      state.meetingsByUserId = Object.assign({}, state.meetingsByUserId);

    },

    storeAMeeting(state: TMeetingsStoreState, meeting: TMeeting): void {
      if (!meeting || !meeting.user_contact) {
        return;
      }
      const meetingUserId: number = meeting.user_contact.user.id;
      if(state.meetingsByUserId[meetingUserId]) {
        state.meetingsByUserId[meetingUserId].push(meeting);
      } else {
        state.meetingsByUserId[meetingUserId] = [meeting];
      }
      state.meetingsByUserId = Object.assign({}, state.meetingsByUserId);
    },

    setStatusCanceledByMeetingId(state: TMeetingsStoreState, meetingId: number): void {
      // N.B. This method has to traverse the whole meetings list because
      // one meeting is represented under two userIds and we don't know these userIds here in this store
      // therefore this loop has no breaks or other exit codes.
      // TODO: do something about it, perhaps pass the whole TMeeting instead of just meetingId and use all userIds from it
      for (const userId in state.meetingsByUserId) {
        for (let meetingIndex = 0; meetingIndex < state.meetingsByUserId[userId].length; meetingIndex++) {
          if (state.meetingsByUserId[userId][meetingIndex].id === meetingId) {
            state.meetingsByUserId[userId][meetingIndex].status = MeetingStatus.Canceled;
          }
        }
      }
    },

    setStatusConfirmedByMeetingId(state: TMeetingsStoreState, meetingId: number): void {
      for (const userId in state.meetingsByUserId) {
        for (let meetingIndex = 0; meetingIndex < state.meetingsByUserId[userId].length; meetingIndex++) {
          if (state.meetingsByUserId[userId][meetingIndex].id === meetingId) {
            state.meetingsByUserId[userId][meetingIndex].status = MeetingStatus.Confirmed;
          }
        }
      }
    },

    setMeetingsScheduleDate(state: TMeetingsStoreState, selectedDate: TEventDay): void {
      state.selectedDate.meetings = selectedDate;
    },
    setContactScheduleDate(state: TMeetingsStoreState, selectedDate: TEventDay): void {
      state.selectedDate.contactSchedule = selectedDate;
    },
    setSidebarContactScheduleDate(state: TMeetingsStoreState, selectedDate: TEventDay): void {
      state.selectedDate.sidebarContactSchedule = selectedDate;
    },
    setSidebarScheduleDate(state: TMeetingsStoreState, selectedDate: TDateSchedule): void {
      state.selectedDate.sidebarSchedule = selectedDate;
    },
    updateContactFavorite(state: TMeetingsStoreState, payload: TUpdateMeetingContactFavoriteParams): void {
      const { isFavorite, contactId } = payload;
      for (const key in state.meetingsByUserId) {
        state.meetingsByUserId[key].forEach((meeting) => {
          if (meeting.contact && meeting.contact.id === contactId) {
            meeting.contact.is_favorite = isFavorite;
          }
        });
      }
      state.meetingsByUserId = Object.assign({}, state.meetingsByUserId);
    },

    setMeetingTooEarlyPopupVisible: (state: TMeetingsStoreState, value: boolean): void => {
      state.isMeetingTooEarlyPopupVisible = value;
    },
    setMeetingCancelPopupVisible: (state: TMeetingsStoreState, value: boolean): void => {
      state.isMeetingCancelPopupVisible = value;
    },
    setMeetingDeclinePopupVisible: (state: TMeetingsStoreState, value: boolean): void => {
      state.isMeetingDeclinePopupVisible = value;
    },
    setMeetingCancel: (state: TMeetingsStoreState, value: boolean): void => {
      state.isMeetingCancel = value;
    },
    setMeetingDecline: (state: TMeetingsStoreState, value: boolean): void => {
      state.isMeetingDecline = value;
    },
    setManagedMeetingsPage: (state: TMeetingsStoreState, value: TApiListResponse<TMeeting>): void => {
      state.managedMeetingsPage = value;
    },
  },
};

export default meetingsStore;
