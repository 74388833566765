import { TContact } from '@/_types/contact.type';
import UserHelper from '@/_helpers/user.helper';
import DateTimeHelper from '@/_helpers/date-time.helper';

export default class ContactHelper {

  public static getFullName(contact: TContact): string {
    if (!contact) {
      return '';
    }
    const { name, surname } = contact;
    const nameCopy = ('' + (name || '')).trim();
    const surnameCopy = ('' + (surname || '')).trim();

    let fullName = nameCopy;
    if (surnameCopy) {
      fullName += ' ' + surnameCopy;
    }

    return fullName;
  }

  public static getFullCompany(contact: TContact): string {
    if (!contact) {
      return '';
    }
    const { company_name, company_position } = contact;
    const nameCopy = ('' + (company_name || '')).trim();
    const positionCopy = ('' + (company_position || '')).trim();

    let fullCompany = nameCopy;
    if (positionCopy) {
      fullCompany += ', ' + positionCopy;
    }

    return fullCompany;
  }

  public static responseToContactConverter(responseObj: any): TContact {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      fullName: ContactHelper.getFullName(responseObj),
      fullCompany: ContactHelper.getFullCompany(responseObj),
      user: UserHelper.responseToUserInfoConverter(responseObj.user),
      first_login_date: DateTimeHelper.apiDateToDate(responseObj.first_login_date)
    });
  }

}
