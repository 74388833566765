import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IDiscoveryServiceProvider } from '@/_types/discovery/discovery-service-provider.interface';
import EwDiscoveryServiceProvider from '@/_providers/ew.discovery-service.provider';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';
import store from '@/store';
import { TDiscoveryServiceProviderConfig } from '@/_types/discovery/discovery-service-provider-config.type';
import { DiscoveryMessageType } from '@/_types/discovery/discovery-message-type.enum';
import { IDiscoveryMessage } from '@/_types/discovery/discovery-message.interface';
import { TChatMessageContext } from '@/_modules/chat/types/chat-message.type';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';
import notificationsService from '@/_modules/promo/services/notifications.service';
import { TMeeting } from '@/_types/meeting/meeting.type';
import MeetingsHelper from '@/_helpers/meetings.helper';
import MessagesHelper from '@/_helpers/messages.helper';
import CompanyVideoChatRoomHelper, { TParticipantSlots } from '@/_helpers/company-video-chat-room.helper';
import PromoProgramHelper from '@/_modules/promo-program/helpers/promo-program-helper';
import { TDiscoverySessionOnlineCheck } from '@/_types/discovery/discovery-session-online-check.type';

const COMPANY_VIDEO_CHAT_STATES_GROUP_NAME = 'company-video-chats';

export type TEventDiscoveryServiceConfig = {
  eventId: number;
  contactId: number;
}

class EventDiscoveryService {

  public connected$: ReplaySubject<TEventDiscoveryServiceConfig> = new ReplaySubject<TEventDiscoveryServiceConfig>(1);

  private _config: TEventDiscoveryServiceConfig = {
    eventId: null,
    contactId: null,
  };
  private _provider: IDiscoveryServiceProvider;
  private _configure$: Subject<TEventDiscoveryServiceConfig> = new Subject<TEventDiscoveryServiceConfig>();

  constructor() {
    this.connected$.next(null);
    this._provider = new EwDiscoveryServiceProvider();
    this._configure$.pipe(debounceTime(1000)).subscribe(this._configure.bind(this));
    this._subscribeToProviderEvents();
  }

  public configure(config: TEventDiscoveryServiceConfig): void {
    this._configure$.next(config);
  }

  public subscribe(topic: string): void {
    this._provider.subscribe(topic);
  }

  public unsubscribe(topic: string): void {
    this._provider.unsubscribe(topic);
  }

  public isConnected(): boolean {
    return this._provider.isConnected();
  }

  public touchCompanyVideoChatState(videoChatState: TCompanyVideoChatState, maxAgeSec: number): void {
    const touchVideoChatState: TCompanyVideoChatState = Object.assign({}, videoChatState, {
      expire: Math.floor((new Date()).getTime() * 0.001) + maxAgeSec,
      participants: CompanyVideoChatRoomHelper.participantArrayToParticipantSlots(videoChatState.participants)
    });
    this._provider.touchState<TCompanyVideoChatState>({
      stateId: videoChatState.id,
      maxAgeSec: maxAgeSec || 1, // 0 = infinite ?
      statesGroupId: COMPANY_VIDEO_CHAT_STATES_GROUP_NAME,
      state: touchVideoChatState,
    });
    this._provider.toTopic<IDiscoveryMessage<TCompanyVideoChatState>>({
      topic: videoChatState.id,
      message: {
        type: DiscoveryMessageType.COMPANY_VIDEO_CHAT_UPDATE,
        data: touchVideoChatState,
      }
    });
    // this._provider.toAll<IDiscoveryMessage<TCompanyVideoChatState>>({
    //   type: DiscoveryMessageType.COMPANY_VIDEO_CHAT_UPDATE,
    //   data: touchVideoChatState,
    // });
  }

  public requestOnlineContacts(): void {
    this._provider.requestOnlineContacts();
  }

  public requestVideoChatStates(): void {
    this._provider.getStatesGroup<TCompanyVideoChatState>(COMPANY_VIDEO_CHAT_STATES_GROUP_NAME).then(companyVideoChatStates => {
      store.dispatch('meetingRoomsStore/setCompanyVideoChatStates', companyVideoChatStates.map(state => {
        return {
          ...state,
          participants: CompanyVideoChatRoomHelper.participantSlotsToParticipantArray(state.participants as TParticipantSlots),
        };
      }));
    });
  }

  public enterChatGroup(chatGroupId: string): void {
    this._provider.enterChatGroup(chatGroupId);
  }

  // public leaveChatGroup(chatGroupId: string): void {
  //   this._provider.leaveChatGroup(chatGroupId);
  // }

  public requestChatGroupMessagesPage(chatGroupId: string, limit: number, lastMessageId?: number): void {
    this._provider.requestChatGroupMessagesPage(chatGroupId, limit, lastMessageId);
  }

  public requestChatGroupContacts(chatGroupId: string): void {
    this._provider.requestChatGroupContacts(chatGroupId);
  }

  public sendChatGroupTextMessage(chatGroupId: string, message: string, context: TChatMessageContext): void {
    this._provider.sendChatGroupTextMessage(chatGroupId, message, context);
  }

  public removeChatGroupTextMessage(chatGroupId: string, messageId: string): void {
    this._provider.removeChatGroupTextMessage(chatGroupId, messageId);
  }

  public notifyAboutWaitingMeeting(contactId: number, context: any): void {
    this._provider.toContact({contactId, message: context});
  }

  public notifyAboutCompanyBroadcastStarted(context: any): void {
    this._provider.toAll({...context});
  }

  public notifyAboutNewsArticle(context: TDiscoveryNewsArticleNotificationResponse): void {
    this._provider.toAll({
      type: DiscoveryMessageType.PUBLIC_NEWS_NOTIFICATION,
      message: context
    });
  }

  public subscribeToProgramSessionTopic(id: number): void {
    this.subscribe(PromoProgramHelper.getOnlineCheckTopicName(id));
  }

  public unSubscribeFromProgramSessionTopic(id: number): void {
    this.unsubscribe(PromoProgramHelper.getOnlineCheckTopicName(id));
  }

  public sendPresenceQuestion(context: TDiscoverySessionOnlineCheck): void {
    this._provider.toTopic({
      topic: PromoProgramHelper.getOnlineCheckTopicName(context.programSessionId),
      message: context
    });
  }

  private async _configure(config: TEventDiscoveryServiceConfig): Promise<void> {
    if (
      !config
      || (this._config.contactId === config.contactId && this._config.eventId === config.eventId)
    ) {
      return;
    }

    if (
      config.eventId !== this._config.eventId
      || config.contactId !== this._config.contactId
    ) {
      this._provider.disconnect();
    }

    this._config.eventId = config.eventId;
    this._config.contactId = config.contactId;

    await this._provider.connect({
      eventId: config.eventId,
      contactId: config.contactId,
    });
  }

  private _subscribeToProviderEvents(): void {
    this._provider.connected$.subscribe(this._onProviderConnected.bind(this));
    this._provider.disconnected$.subscribe(this._onProviderDisconnected.bind(this));
    this._provider.message$.subscribe(this._onProviderMessage.bind(this));
  }

  private _onProviderConnected(config: TDiscoveryServiceProviderConfig): void {
    this.connected$.next(config);
    this.requestVideoChatStates();
  }

  private _onProviderDisconnected(): void {
    this.connected$.next(null);
  }

  private _onProviderMessage(message: IDiscoveryMessage<any>): void {

    // console.log('');
    // console.log('_onProviderMessage', message);
    // console.log('');

    switch (message.type) {

      case DiscoveryMessageType.COMPANY_VIDEO_CHAT_UPDATE: {
        const messageDataProcessed: any = {
          ...message.data,
          participants: CompanyVideoChatRoomHelper.participantSlotsToParticipantArray(message.data.participants as TParticipantSlots)
        };
        store.dispatch('meetingRoomsStore/setCompanyVideoChatState', messageDataProcessed);
        break;
      }

      case DiscoveryMessageType.CHAT_GROUP_MESSAGES_PAGE: {
        store.dispatch('chatStore/chatGroupMessagesPageResponse', message.data);
        break;
      }

      case DiscoveryMessageType.CHAT_MESSAGE: {
        store.dispatch('chatStore/chatGroupMessage', message.data);
        break;
      }

      case DiscoveryMessageType.CHAT_MESSAGE_DELETE: {
        store.dispatch('chatStore/removeChatGroupMessage', message.data);
        break;
      }

      case DiscoveryMessageType.CHAT_GROUP_CONTACTS: {
        store.dispatch('chatStore/chatGroupContactsResponse', message.data);
        break;
      }

      case DiscoveryMessageType.CHAT_GROUP_CONTACT_CONNECTED: {
        store.dispatch('chatStore/chatGroupContactConnectedResponse', message.data);
        break;
      }

      case DiscoveryMessageType.CHAT_GROUP_CONTACT_DISCONNECTED: {
        store.dispatch('chatStore/chatGroupContactDisconnectedResponse', message.data);
        break;
      }

      case DiscoveryMessageType.MEETING_IS_WAITING: {
        store.dispatch('notificationsStore/setWaitingMeetingNotification', message.data);
        break;
      }

      case DiscoveryMessageType.ONLINE_CONTACTS: {
        store.dispatch('contactsStore/setOnlineContactIds', message.data.contactIds);
        break;
      }

      case DiscoveryMessageType.SESSION_ONLINE_CHECK: {
        store.dispatch('promoProgramStore/setPresenceQuestionData', message.data);
        break;
      }

      case DiscoveryMessageType.PUBLIC_NEWS_NOTIFICATION: {
        store.dispatch('notificationsStore/setNewsArticleNotification', message.data);
        break;
      }

      case DiscoveryMessageType.NEW_MESSAGE: {
        store.dispatch('_messageStore/pushMessage', { message: MessagesHelper.responseToMessageItemConverter(message.data) });
        notificationsService.processNewMessageSocketNotification(message.data);
        break;
      }

      case DiscoveryMessageType.MEETING_CANCELED: {
        if (message.data) {
          let userId: number = null;
          try {
            userId = store.getters['promoPageStore/contact'].user.id;
          } catch {
            /* ignore */
          }
          if (userId) {
            store.dispatch('meetingsStore/requestUserMeetings', {
              userId: userId,
              force: true
            });
          }

          // TODO: uncomment when needed: notificationsService.processMeetingCanceledSocketNotification(meeting);
        }
        break;
      }

      case DiscoveryMessageType.MEETING_REQUEST: {
        if (message.data) {
          const meeting: TMeeting = MeetingsHelper.responseToMeetingsConverter(message.data as TMeeting);
          store.dispatch('meetingsStore/storeAMeeting', meeting);
          notificationsService.processMeetingRequestSocketNotification(meeting);
        }
        break;
      }

      case DiscoveryMessageType.MEETING_CONFIRMED: {
        if (message.data) {
          const meeting: TMeeting = MeetingsHelper.responseToMeetingsConverter(message.data as TMeeting);
          store.dispatch('meetingsStore/storeAMeeting', meeting);
          notificationsService.processMeetingConfirmedSocketNotification(meeting);
        }
        break;
      }

      case DiscoveryMessageType.MEETING_REMINDER: {
        if (message.data) {
          const meeting: TMeeting = MeetingsHelper.responseToMeetingsConverter(message.data as TMeeting);
          notificationsService.processMeetingReminderSocketNotification(meeting);
        }
        break;
      }
    }
  }
}

const eventDiscoveryService = new EventDiscoveryService();
export default eventDiscoveryService;
