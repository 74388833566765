


















import {Vue, Component, Watch} from 'vue-property-decorator';
import helpCrunchService, { THelpCrunchConfig } from '@/_services/help-crunch.service';
import {Getter} from 'vuex-class';
import {TUser} from '@/_types/user.type';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';

@Component
export default class HelpCrunchIcon extends Vue {

  @Getter('_userStore/user') public readonly user: TUser;
  @Getter('_eventStore/event') public readonly event: TEvent;
  @Getter('promoPageStore/contact') public readonly contact: TContact;
  @Getter('promoPageStore/promoPage') public readonly promoPage: TPromoPage;

  public isHelpCrunchOpened: boolean = false;
  public isHelpCrunchReady: boolean = false;
  public isListenersInitialized: boolean = false;
  public unreadCount: number = 0;

  public created(): void {
    helpCrunchService.locale = this.$i18n.locale;
    this.configureHelpCrunch();
  }

  public get isHelpCrunchButtonVisible(): boolean {
    return this.isHelpCrunchReady && this.isListenersInitialized;
  }

  public get unreadCountText(): string {
    const val: number = this.unreadCount;
    return val ? (val > 99 ? '99+' : val.toFixed(0)) : '';
  }

  public get helpCrunchConfig(): THelpCrunchConfig {
    return {
      user: this.user,
      event: this.event,
      contact: this.contact,
      promoPage: this.promoPage,
    };
  }

  @Watch('helpCrunchConfig', { immediate: true })
  private onHelpCrunchConfigChange(): void {
    this.configureHelpCrunch();
  }

  private configureHelpCrunch(): void {
    helpCrunchService.configure(this.helpCrunchConfig);
    this.initializeHelpCrunchListeners();
  }

  private initializeHelpCrunchListeners(): void {
    if (!helpCrunchService._helpCrunch || !helpCrunchService.isInitialized) {
      window.setTimeout(() => {
        this.initializeHelpCrunchListeners();
      }, 1000);
      return;
    }
    if (this.isListenersInitialized) {
      return;
    }
    helpCrunchService._helpCrunch('onReady', () => {
      this.onReady();
    });
    helpCrunchService._helpCrunch('onChatOpen', () => {
      this.onChatOpen();
    });
    helpCrunchService._helpCrunch('onChatClose', () => {
      this.onChatClose();
    });
    helpCrunchService._helpCrunch('onNewUnreadChat', (event: any) => {
      this.onNewUnreadChat(event);
    });
    this.isListenersInitialized = true;
  }

  private onReady(): void {
    this.isHelpCrunchReady = true;
  }

  private onChatOpen(): void {
    this.isHelpCrunchOpened = true;
    this.markHelpCrunchPresence();
  }

  private onChatClose(): void {
    this.isHelpCrunchOpened = false;
    this.markHelpCrunchPresence();
  }

  private onNewUnreadChat(event: any): void {
    try {
      this.unreadCount = event.unreadChats;
    } catch {
      this.unreadCount = 0;
    }
  }

  private markHelpCrunchPresence(): void {
    const markName = 'root-helpcrunch-chat-opened';
    if (this.isHelpCrunchOpened) {
      document.documentElement.classList.add(markName);
      return;
    }
    document.documentElement.classList.remove(markName);
  }

  public toggleHelpCrunchChat(): void {
    if (!helpCrunchService.isInitialized) {
      return;
    }
    helpCrunchService._helpCrunch(this.isHelpCrunchOpened ? 'closeChat' : 'openChat');
    this.isHelpCrunchOpened = !this.isHelpCrunchOpened;
  }

}
