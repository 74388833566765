export enum DateTimeFormat {
  API_DATE_SMALL = 'YYYY-MM-DDTHH:mm',
  API_DATE_MEDIUM = 'YYYY-MM-DDTHH:mm:ss',
  DATE_TINY = 'YYYY-MM-DD',
  DATE_MEDIUM = 'YYYY-MM-DD HH:mm:ss',
  MONTH_DATE = 'MMMM D',
  MONTH_DATE_SHORT = 'MMM D',
  MONTH_DATE_US = 'MMM D, YYYY, MMMM, dddd',
  SHORT_DATE = 'L HH:mm',
  ONLY_DATE = 'L',
  HOURS_MINUTES = 'HH:mm',
  DAY_NAME_DATE_MONTH_UK = 'dddd D MMMM',
  DAY_NAME_DATE_MONTH_EN = 'dddd, MMMM D',
  NEWS_PUBLICATION_DATE_EN = 'dddd, MMMM D YYYY, HH:mm',
  NEWS_PUBLICATION_DATE_UK = 'dddd, D MMMM YYYY, HH:mm',
  DAY_MONTH_TIME = 'DD MMM HH:mm',
  DAY_MONTH_TIME_YEAR = 'DD MMM YYYY HH:mm',
  DAY_NAME_DATE_MONTH_YEAR = 'dddd D MMMM, YYYY',

}
