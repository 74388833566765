import Vue from 'vue';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import store from '@/store';
import router from '@/router';
import i18n, { APP_AVAILABLE_LANGUAGES } from '@/i18n';
import helpCrunchService from '@/_services/help-crunch.service';

export default class RouterHelper {

  public static authorized(to: Route, from: Route, next: NavigationGuardNext): void {
    if (!store.state.authStore.token) {
      if (to && to.params && to.params.eventId) {
        router.push({ name: 'join-event', params: { eventId: to.params.eventId, redirect: to.path } }).catch(() => {/* ignore */});
        return;
      }
      router.push({ name: 'auth-page', params: { redirect: to.path } });
      return;
    }
    next();
  }

  public static setLang(to: Route, from: Route, next: NavigationGuardNext): void {
    if (APP_AVAILABLE_LANGUAGES.indexOf(to.params.lang) === -1) {
      router.push({
        name: to.name || undefined,
        params: Object.assign({}, to.params, { lang: i18n.locale }),
      });
      return;
    }

    if (i18n.locale !== to.params.lang) {
      i18n.locale = to.params.lang;
      Vue.moment.locale(to.params.lang);
      helpCrunchService.locale = to.params.lang;
    }
    next();
  }
}
