


import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { TEvent, TEventSettings } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwPassword from '@/_modules/standalone-company/components/UI/ew-password/ew-password.vue';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import EventInfo from '@/_modules/standalone-company/components/event-info/event-info.vue';
import EwPopUp from '@/_modules/standalone-company/components/ew-pop-up/ew-pop-up.vue';
import VueI18n from 'vue-i18n';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import { Validations } from 'vuelidate-property-decorators';
import loginApi, {
  TPasswordChangeConfirmRequestParams,
  TSendLoginRequestParams,
  TUserAuthData
} from '@/_api/login/login.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import HelpCrunchService from '@/_services/help-crunch.service';
import UtilsHelper from '@/_helpers/utils.helper';
import { Location } from 'vue-router';
import TranslateResult = VueI18n.TranslateResult;

const CODE_COUNTER = 50;

@Component({
  components: {
    EwButton,
    EwInput,
    EwPopUp,
    EwPassword,
    EventInfo,
  }
})
export default class EwAuth extends Vue {
  @Ref('confirmCodePart1') confirmCodePart1: any;
  @Ref('confirmCodePart2') confirmCodePart2: any;
  @Ref('confirmCodePart3') confirmCodePart3: any;

  @Ref('refSignInCheck') refSignInCheck: any;
  @Ref('refSignIn') refSignIn: any;
  @Ref('refSignUp') refSignUp: any;
  @Ref('refMyName') refMyName: any;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isShowStandAloneAuth') isShowStandAloneAuth: boolean;
  @Getter('authStore/isEmailRegistered') isEmailRegistered: boolean;
  @Getter('authStore/authPopupTargetRoute') authPopupTargetRoute: Location;
  @Getter('authStore/authError') authError: ApiErrorResponseData;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('promoPageStore/individualPromoPage') company: TPromoPage;

  @Getter('eventStore/showEventAccessDisabledPopup') showEventAccessDisabledPopup: boolean;

  @State('authStore/status') status: string;
  @State('authStore/confirmId') confirmId: string;

  @Action('authStore/emailLookup') emailLookup: (params: { email: string }) => Promise<boolean>;
  @Action('authStore/signIn') signIn: (params: TSendLoginRequestParams) => Promise<any>;
  @Action('authStore/signUp') signUp: (params: TSendLoginRequestParams) => Promise<any>;
  @Action('promoPageStore/refresh') refreshMyself: () => void;
  @Action('authStore/setShowStandaloneAuth') setShowStandaloneAuth: (params: boolean) => Promise<any>;

  public isShowCheckLoginPopUp: boolean = false;
  public isShowSignInPasswordPopUp: boolean = false;
  public isShowSignUpPasswordPopUp: boolean = false;
  public isShowConfirmCodePopUp: boolean = false;
  public isShowContactInfoPopUp: boolean = false;
  public isShowTicketPopUp: boolean = false;

  public isConfirmCodeVerified: boolean = false;
  public isRestoreButtonDisabled: boolean = false;
  public isCheckLoginError: boolean = false; // FIXME: remove?
  public confirmCodeParts: number[] | undefined[] = [];
  public confirmCode: string = '';
  public resendCodeCounter: number = CODE_COUNTER;
  public intervalCounter: number;

  public showWhereGetCode: TranslateResult = '';

  public userEmail: string = '';
  public emailIsIncorrect: TranslateResult = '';
  public userPassword: string = '';
  public passwordIsIncorrect: TranslateResult = '';

  public ticketCode: string = '';
  public ticketCodeIsIncorrect: TranslateResult = '';

  public myName: string = '';
  public mySurname: string = '';
  public myCompanyName: string = '';
  public myJobPosition: string = '';
  public myPhoneNumber: string = '';

  public myNameIsIncorrect: TranslateResult = '';
  public mySurnameIsIncorrect: TranslateResult = '';
  public myCompanyNameIsIncorrect: TranslateResult = '';
  public myJobPositionIsIncorrect: TranslateResult = '';
  public myPhoneNumberIsIncorrect: TranslateResult = '';

  @Validations()
  public readonly validations: TVuelidateRuleSet<string> | any = {
    userEmail: {
      email: email
    },
    userPassword: { minLength: minLength(5) },
    myName: { required },
    mySurname: { required },
    myCompanyName: { required },
    myJobPosition: { required },
  };

  public get userData(): TUserAuthData {
    return this.$store.state.authStore.userData;
  }

  public get userToken(): TUserAuthData {
    return this.$store.state.authStore.token;
  }

  public get authStatus(): TUserAuthData {
    return this.$store.state.authStore.status;
  }

  public get deviceId(): string {
    return this.$store.state.authStore.deviceId;
  }

  public get confirmationId(): string {
    return this.$store.state.authStore.confirmId;
  }

  public get eventId(): number {
    return this.event && this.event.id;
  }

  public get eventLanguages(): string[] {
    if (!this.event || !this.event.languages || !this.event.languages.length) {
      return ['en'];
    }
    return this.event.languages;
  }

  public get getAuthError(): string {
    return this.authError && (this.authError.error || '');
  }

  public get forgotPasswordRequestError(): string {
    return this.$store.state.authStore.forgotPasswordRequestError;
  }

  public get changePasswordRequestError(): string {
    return this.$store.state.authStore.changePasswordRequestError.error;
  }

  public get myContactName(): string {
    return this.myself && (this.myself.name || '');
  }

  public get myContactSurname(): string {
    return this.myself && (this.myself.surname || '');
  }

  public get myContactCompanyPosition(): string {
    return this.myself && (this.myself.company_position || '');
  }

  public get myContactCompanyName(): string {
    return this.myself && (this.myself.company_name || '');
  }

  public get myContactPhone(): string {
    return this.myself && (this.myself.phone || '');
  }

  @Watch('isAuthenticated',)
  public async isAuthenticatedChanged(): Promise<void> {
    if (this.isAuthenticated && this.$route.name === 'auth-page') {
      await this.$store.dispatch('authStore/setShowStandaloneAuth', false);
      this.closeSignUpPasswordPopUp();
      this.closeSignInPasswordPopUp();
      await this.authRedirectToSavedRoute();
    }
  }

  @Watch('authStatus', { immediate: true })
  public async onAuthStatusChanged(newVal: string): Promise<void> {

    if (newVal === 'success') {

      if (this.userToken && this.userData && this.event) {

        if (this.event && this.event.access_type === 'free') {
          await this.$store.dispatch('eventStore/toggleParticipation', {
            event_id: this.$route.params.eventId,
            going: true
          });
          if (this.$route.name === 'auth-page') {
            await this.authRedirectToSavedRoute();
          }
        } else {
          // We have to update personal.has_access to check it below
          await this.$store.dispatch('_eventStore/reset');
          await this.$store.dispatch('_eventStore/getEvent', this.$route.params.eventId);

          if (this.event && this.event.personal.has_access) {
            await this.authRedirectToSavedRoute();
            return;
          }

          this.isShowTicketPopUp = true;
        }

        if (this.isAuthenticated) {
          this.isShowCheckLoginPopUp = false;
          this.isShowSignInPasswordPopUp = false;
          this.isShowSignUpPasswordPopUp = false;
          this.isShowConfirmCodePopUp = false;
        }
        this.fillContactFields();
      }
    }

  }

  @Watch('confirmCodeParts')
  public onConfirmCodeElemChanged(): void {

    if (this.confirmCodeParts[0]) {
      this.confirmCodePart1.inputElement.focus();
    }
    if (this.confirmCodeParts[1]) {
      this.confirmCodePart2.inputElement.focus();
    }
    if (this.confirmCodeParts[2]) {
      this.confirmCodePart3.inputElement.focus();
    }

    this.confirmCode = '';
    if (this.confirmCodeParts[0] && this.confirmCodeParts[1] && this.confirmCodeParts[2] && this.confirmCodeParts[3]) {
      this.confirmCodeParts.forEach(x => {
        this.confirmCode += x;
      });
    }

    for (let i = 0; i < this.confirmCodeParts.length; i++) {
      if (!this.confirmCodeParts[i]) {
        this.isRestoreButtonDisabled = true;
        break;
      }
      this.isRestoreButtonDisabled = false;
    }
  }

  public cleanMyPhoneNumber(): void {
    const cleanValue: string = this.myPhoneNumber.replace(/[^\d]/g, '');
    if (cleanValue !== '') {
      this.myPhoneNumber = '+' + cleanValue;
    } else {
      this.myPhoneNumber = '';
    }
  }

  public async updateContact(): Promise<void> {
    // this.$v.$touch();
    const payload: any = {
      event_id: this.eventId,
      name: this.myName,
      surname: this.mySurname,
      company_name: this.myCompanyName,
      company_position: this.myJobPosition,
      phone: this.myPhoneNumber,
      tags: [],
      lang: ''
    };

    const success = await Promise.all(this.eventLanguages.map(async (langCode) => {
      payload.lang = langCode;
      await this.$store.dispatch('promoPageStore/editContact', payload);
    }));

    if (success.length) {
      this.isShowContactInfoPopUp = false;

      if (this.event && this.event.access_type === 'free') {
        await this.$store.dispatch('eventStore/toggleParticipation', {
          event_id: this.$route.params.eventId,
          going: true
        });
        await this.authRedirectToSavedRoute();
      } else {
        // We have to update personal.has_access to check it below
        await this.$store.dispatch('_eventStore/reset');
        await this.$store.dispatch('_eventStore/getEvent', this.$route.params.eventId);

        if (this.event && this.event.personal.has_access) {
          if (this.$route.name !== 'promo-page-cabinet-event-settings') {
            await this.authRedirectToSavedRoute();
          }
        } else if (this.event && !this.event.personal.has_access) {
          this.isShowTicketPopUp = true;
        }
      }
    }
  }

  public startResendCodeCounter(): void {
    if (this.resendCodeCounter === CODE_COUNTER) {
      loginApi.resendCode({ confirmation_id: this.confirmationId });
    }
    clearInterval(this.intervalCounter);
    this.intervalCounter = window.setInterval(() => {
      if (this.resendCodeCounter > 0) {
        this.resendCodeCounter--;
      } else {
        clearInterval(this.intervalCounter);
        this.resendCodeCounter = CODE_COUNTER;

      }
    }, 1000);
  }

  public async signUpReq(): Promise<void> {
    if (this.$v.userPassword.minLength) {
      // await this.emailLookup({email: this.userEmail});

      const payload: TSendLoginRequestParams = {
        login: this.userEmail,
        password: this.userPassword,
        platform: 'WEB',
        device_id: this.deviceId,
      };

      await this.signUp(payload);

      if (this.getAuthError) {
        this.passwordIsIncorrect = this.getAuthError;
      }

      this.showContactInfo();
    }

    if (!this.$v.userPassword.minLength) {
      this.passwordIsIncorrect = 'Too short password';
    }
  }

  public async emailCheck(): Promise<void> {
    if (this.$v.userEmail.email) {
      await this.emailLookup({ email: this.userEmail });
      if (this.authError) {
        this.emailIsIncorrect = 'Too much requests';
        return;
      }

      if (this.isEmailRegistered) {
        this.isShowCheckLoginPopUp = false;
        this.isShowSignInPasswordPopUp = true;
        this.$nextTick(() => {
          this.refSignIn.inputElement.focus();
        });
      } else {
        this.isShowCheckLoginPopUp = false;
        this.isShowSignUpPasswordPopUp = true;
        this.$nextTick(() => {
          this.refSignUp.inputElement.focus();
        });
      }

    }

    if (!this.$v.userEmail.email) {
      this.emailIsIncorrect = this.$t('errors.validation.email_invalid');
    }

  }

  public closeCheckLoginPopUp(): void {
    this.isShowCheckLoginPopUp = false;
    this.setShowStandaloneAuth(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeSignInPasswordPopUp(): void {
    this.isShowSignInPasswordPopUp = false;
    this.setShowStandaloneAuth(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeSignUpPasswordPopUp(): void {
    this.isShowSignUpPasswordPopUp = false;
    this.setShowStandaloneAuth(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeConfirmCodePopUp(): void {
    this.isShowConfirmCodePopUp = false;
    this.setShowStandaloneAuth(false);
    this.userEmail = '';
    this.userPassword = '';
  }

  public closeHasAccessPopUp(): void {
    this.isShowTicketPopUp = false;
    this.showWhereGetCode = '';
    this.$store.dispatch('authStore/setShowStandaloneAuth', false);
  }

  public showConfirmCodePopUp(): void {
    this.isShowConfirmCodePopUp = true;
    this.isShowSignInPasswordPopUp = false;
    this.isShowSignUpPasswordPopUp = false;
    this.userPassword = '';
  }

  public closeContactInfoPopUp(): void {
    this.isShowContactInfoPopUp = false;
  }

  @Watch('myself', { deep: true })
  private onMyselfChange(): void {
    this.fillContactFields();

    if (this.myself && this.isAuthenticated) {
      if (!this.myContactName
        || !this.myContactSurname
        || !this.myContactCompanyPosition
        || !this.myContactCompanyName) {
        this.isShowContactInfoPopUp = true;
        this.$nextTick(() => {
          this.refMyName.inputElement.focus();
        });
      }
    }
  }

  public async signInReq(): Promise<void> {
    if (!this.userPassword || !this.userEmail) { return; }
    const payload: TSendLoginRequestParams = {
      login: this.userEmail,
      password: this.userPassword,
      platform: 'WEB',
      device_id: this.deviceId,
    };

    await this.signIn(payload);
    if (this.getAuthError) {
      this.passwordIsIncorrect = this.getAuthError;
    }

  }

  @Watch('isShowStandAloneAuth', { immediate: true })
  private onIsShowStandAloneAuthChange(): void {
    if (this.isAuthenticated && this.event && !this.event.personal.has_access) {
      this.isShowTicketPopUp = true;
    } else {
      this.isShowCheckLoginPopUp = this.isShowStandAloneAuth;
    }

    if (this.isShowCheckLoginPopUp) {
      this.$nextTick(() => {
        this.refSignInCheck.inputElement.focus();
      });

    }
  }

  public showContactInfo(): void {
    if (this.isAuthenticated && this.eventId) {
      this.isShowContactInfoPopUp = true;
    }

  }

  public fillContactFields(): void {
    this.myName = this.myContactName;
    this.mySurname = this.myContactSurname;
    this.myCompanyName = this.myContactCompanyName;
    this.myJobPosition = this.myContactCompanyPosition;
    this.myPhoneNumber = this.myContactPhone;
  }

  public async getConfirmationId(): Promise<void> {
    await this.$store.dispatch('authStore/forgotPassword', this.userEmail);
    if (this.confirmationId && !this.forgotPasswordRequestError) {
      this.isConfirmCodeVerified = true;
    }
    if (this.forgotPasswordRequestError) {
      this.passwordIsIncorrect = this.forgotPasswordRequestError;
    }
  }

  public async restorePassword(): Promise<void> {
    await this.getConfirmationId();
    if (this.isConfirmCodeVerified) {
      const payload: TPasswordChangeConfirmRequestParams = {
        confirmation_id: this.confirmationId,
        code: this.confirmCode,
        password: this.userPassword,
        platform: 'WEB',
        device_id: this.deviceId,
      };
      await this.$store.dispatch('authStore/changePassword', payload);
      if (this.changePasswordRequestError) {
        this.passwordIsIncorrect = this.changePasswordRequestError;
      }
    }
  }

  private openSupportChat(): void {
    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) {
      window.setTimeout(() => { this.openSupportChat(); }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

  public async sendTicketCode(): Promise<void> {
    const response = await this.$store.dispatch('eventStore/activateCode', {
      event_id: this.$route.params.eventId,
      code: this.ticketCode
    });

    if (response && response.status === 202) {
      this.isShowTicketPopUp = false;
      this.showWhereGetCode = '';
      await this.$store.dispatch('authStore/setAuthPopupVisible', { isAuthPopupVisible: false });
      await this.$store.dispatch('_eventStore/refresh');
      await this.$store.dispatch('promoPageStore/refresh'); // AW-1622
      await this.authRedirectToSavedRoute();
    } else {
      this.ticketCodeIsIncorrect = this.$t('promoAccessCheck.accessNotGranted');
    }
  }

  private async authRedirectToSavedRoute(): Promise<void> {
    if (this.isShowStandAloneAuth) {
      if (this.authPopupTargetRoute) {
        try {
          await this.$router.push(this.authPopupTargetRoute);
          this.$store.dispatch('authStore/clearAuthPopupTargetRoute');
        } catch {
          /* ignore */
        }
      } else {
        this.redirectToDefaultRoute();
      }

      this.isShowCheckLoginPopUp = false;
      this.isShowSignInPasswordPopUp = false;
      this.isShowSignUpPasswordPopUp = false;
      this.isShowConfirmCodePopUp = false;
      this.isShowContactInfoPopUp = false;
      this.isShowTicketPopUp = false;

      return;
    }

    // Sign-in.vue is inline the page, not in the popup
    try {
      await this.$router.push(this.$route.params.redirect || { name: 'home' });
    } catch {
      /* ignore */
    }
  }

  private redirectToDefaultRoute(): void {
    if (this.$route.name !== 'event-info') {
      return;
    }
    const defaultRouteName = UtilsHelper.getDefaultRouteNameFromEventSettings(this.eventSettings);
    if (defaultRouteName && defaultRouteName !== 'event-info') {
      try {
        this.$router.push({
          name: defaultRouteName,
          params: {
            eventId: this.$route.params.eventId
          },
        }).catch(() => {
          /* ignore */
        });
      } catch {
        /* ignore */
      }
    }
  }

  public onWhereGetCode(): void {
    this.showWhereGetCode = this.$t('authPage.screens.answerWhereGetCode');
  }

  public backToPassword(): void {
    this.isShowConfirmCodePopUp = false;

    if (this.isEmailRegistered) {
      this.isShowSignInPasswordPopUp = true;
      this.$nextTick(() => {
        this.refSignIn.inputElement.focus();
      });
    } else {
      this.isShowSignUpPasswordPopUp = true;
      this.$nextTick(() => {
        this.refSignUp.inputElement.focus();
      });
    }
  }
}
