import api from '../../api';

export default {
  strict: true,
  namespaced: true,
  state: {
    countryList: [],
    cityList: [],
    loading: false,
    locationError: ''
  },
  getters: {
    getCountryList(state) {
      return state.countryList;
    },
    cityList(state) {
      return state.cityList;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    countryList(state, data) {
      state.countryList = data;
    },
    cityList(state, data) {
      state.cityList = data;
    },
    loading(state, data) {
      state.loading = data
    },
    locationError(state, data) {
      state.locationError = data
    }
  },
  actions: {
    countryList: async function ({commit, rootState}) {
      let token = rootState.authStore.token;
      await api.createEvent.country(token)
        .then((resolve) => {
          commit('countryList', resolve.List)
        }).catch((e) => {
          commit('locationError', e.data);
        })
    },
    cityList: async function ({commit, rootState}, country_id) {
      let token = rootState.authStore.token;
      commit('loading', true);
      await api.createEvent.city({token, country_id})
        .then((resolve) => {
          commit('cityList', resolve.List);
          commit('loading', false);
        }).catch((e) => {
          commit('locationError', e.data);
          commit('loading', false);
        })
    },
  }
}

