


import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import IconPasswordEye from '@/_modules/icons/components/icon-password-eye.vue';
import { Prop, Vue, Watch, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import { TSendLoginRequestParams } from '@/_api/login/login.api';
import { Route} from 'vue-router';
import {EventAccessType, TEvent, TEventSettings} from '@/_types/event.type';
import { AuthScreenStep } from '@/_types/auth-screen-step.enum';
import HelpCrunchService from '@/_services/help-crunch.service';
import { TranslateResult } from 'vue-i18n';
import ValidationHelper from '@/_helpers/validation.helper';
import UtilsHelper from '@/_helpers/utils.helper';
import {
  AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME,
  AuthSources
} from '@/_modules/gtm-tracking/track-userid-userstatus/track-userid-userstatus.vue';

import ErrorInfo from '@/_modules/error-info/error-info.vue';

@Component({
  components: {
    IconArrowLeft,
    IconPasswordEye,
    ErrorInfo
  },
  computed: {
    ...mapState('authStore', {
      authStatus: 'status',
      authError: 'authError',
      forgotPasswordRequestStatus: 'forgotPasswordRequestStatus',
      forgotPasswordRequestError: 'forgotPasswordRequestError',
    }),
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthLoading: 'authStore/isLoading',
      authPopupTargetRoute: 'authStore/authPopupTargetRoute',
      event: '_eventStore/event',
      eventSettings: '_eventStore/eventSettings'
    }),
    ...mapState(
      'eventStore', ['showPromoCheckAccessPopup']
    ),
  },
})
export default class SignUp extends Vue {

  public readonly event: TEvent;
  public readonly authStatus: string;
  public readonly authError: ApiErrorResponseData;
  public readonly isAuthLoading: boolean;
  public readonly isAuthenticated: boolean;
  public readonly authPopupTargetRoute: Route;
  public readonly forgotPasswordRequestStatus: string;
  public readonly forgotPasswordRequestError: ApiErrorResponseData;
  public readonly showPromoCheckAccessPopup: boolean;

  public readonly eventSettings: TEventSettings;

  public AuthScreenStep: typeof AuthScreenStep = AuthScreenStep;
  public visibleAuthScreen: AuthScreenStep = AuthScreenStep.PHONE_EMAIL_INPUT;

  // User credentials input-related
  public userLogin: string = '';
  public userPassword: string = '';
  public loginIsIncorrect: TranslateResult = '';

  // Global flag for programmatic enable|disable the send user credentials.
  // See computed isSendLoginButtonDisabled() for complete picture
  public isSendLoginButtonEnabled: boolean = true;

  public errorText: string = '';
  public count: number = 10;
  public counter: number = null;
  public isResendButtonDisabled: boolean = false;
  public resendEnablerTimeoutId: number = -1;

  // Stores the ticket code provided if the event needs it
  public eventAccessCode: string = '';
  public isEventAccessCodeSending: boolean = false;
  public eventAccessCodeError: TranslateResult = '';

  // The email or phone to where the forgot password email will be sent
  public forgotPasswordAddress: string = '';

  public newPassword: string = '';

  // Password visibility flag for the main password input. Usage is in the computed getter 'passwordInputTypeAttribute'
  public passwordVisibilityEnterPassword: boolean = false;

  // Password visibility flag for the main password input. Usage is in the computed getter 'newPasswordInputTypeAttribute'
  public passwordVisibilityNewPassword: boolean = false;

  @Prop({default: false}) public readonly isInPopup: boolean;

  public get isProgressIndicatorVisible(): boolean {
    const isAuthLoading = this.authStatus === 'loading';
    const isForgotPasswordLoading = this.forgotPasswordRequestStatus === 'loading';
    return isAuthLoading || this.isEventAccessCodeSending || isForgotPasswordLoading;
  }

  public get authErrorText(): TranslateResult {
    if (!this.authError) {
      return '';
    }
    let translationKeyName = 'unknown';
    switch (this.authError.code) {
      case 14:
        translationKeyName = 'wrongLoginOrPassword';
        break;
      case 7:
        translationKeyName = 'forbiddenByFirewall';
        break;
      case 15:
        translationKeyName = 'alreadyExists';
        break;
      default:
        if (this.authError.error) {
          return this.authError.error;
        }
    }
    return this.$t('authPage.screens.phoneEmail.errors.' + translationKeyName);
  }

  public get passwordInputTypeAttribute(): string {
    if (this.passwordVisibilityEnterPassword) {
      return 'text';
    }
    return 'password';
  }

  public get newPasswordInputTypeAttribute(): string {
    if (this.passwordVisibilityNewPassword) {
      return 'text';
    }
    return 'password';
  }

  public get isSendLoginButtonDisabled(): boolean {
    const isUserLoginFilled: boolean = this.userLogin !== '';
    const isUserPasswordFilled: boolean = this.userPassword !== '';
    const isUserLoginIncorrect: boolean = this.loginIsIncorrect !== '';
    return !this.isSendLoginButtonEnabled || !isUserLoginFilled || !isUserPasswordFilled || isUserLoginIncorrect;
  }

  public get isSendYourCodeButtonDisabled(): boolean {
    return this.eventAccessCode === '';
  }

  public get isForgotPasswordButtonDisabled(): boolean {
    return this.forgotPasswordAddress === '';
  }

  public get deviceId(): string {
    return this.$store.state.authStore.deviceId;
  }

  public get forgotPasswordEmailError(): string {
    return (this.forgotPasswordRequestError && this.forgotPasswordRequestError.error) ? this.forgotPasswordRequestError.error : '';
  }

  public get confirmId(): string {
    return this.$store.state.authStore.confirmId;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public async mounted(): Promise<void> {
    // Mounted hook is needed for the case when
    // a user tried to enter smth via a URL, i.e. /promo/
    // User is then being redirected to EventPage with «show popup» flag
    // authPopup gets triggered because of the flag, and we have to check:
    // -- if the user is already logged in, show them the eventAccessCode screen
    if (this.isAuthenticated && this.$route.params.eventId && this.event && this.event.personal.has_access !== true) {
      await this.runAuthRoutines();
    }
  }

  public beforeDestroy(): void {
    this.$store.dispatch('authStore/clearAuthError');
  }

  @Watch('authStatus', {immediate: false})
  private async onAuthStatusChange(newVal: string): Promise<void> {
    if (newVal === 'success') {

      this.trackSignUpSuccessToGTM();
      this.saveAuthSourceForGTM();

      // Access check when there is an event
      if (this.$route.params.eventId && this.event && this.event.id && !this.event.personal.has_access) {

        await this.runAuthRoutines();
        return;

      }

      await this.authRedirectToSavedRoute();

    }
  }

  private async runAuthRoutines(): Promise<void> {
    /* Free admission? Set «i will go» to true
     * Else show the «EventAccessCode screen» */
    if (this.event.access_type === EventAccessType.FREE) {
      await this.$store.dispatch('eventStore/toggleParticipation', {
        event_id: this.$route.params.eventId,
        going: true
      });
      await this.authRedirectToSavedRoute();
    } else {
      // We have to update personal.has_access so that we can check it later
      await this.$store.dispatch('_eventStore/reset');
      await this.$store.dispatch('_eventStore/getEvent', this.eventId);

      if (this.event.personal.has_access) {
        await this.authRedirectToSavedRoute();
        return;
      }
      this.showEventAccessCodeScreen();
    }
  }

  /* Whatever actions need to be done on keyup in userLogin, userPassword */
  private eventAccessCodeKeyupHandler(): void {
    this.eventAccessCodeError = '';
    this.eventAccessCode = this.eventAccessCode.trim();
  }

  /* Handlers for keyup.enter — keyboard UX, AW-2390 */
  private onSignUpScreenKeyupEnter(): void {
    this.signUp();
  }

  private onTicketCodeScreenKeyupEnter(): void {
    this.checkEventCode();
  }

  private redirectToDefaultRoute(): void {
    if (this.$route.name !== 'event-info') {
      return;
    }
    const defaultRouteName = UtilsHelper.getDefaultRouteNameFromEventSettings(this.eventSettings);
    if (defaultRouteName && defaultRouteName !== 'event-info') {
      try {
        this.$router.push({
          name: defaultRouteName,
          params: {
            eventId: this.$route.params.eventId
          },
        }).catch(() => {
          /* ignore */
        });
      } catch {
        /* ignore */
      }
    }
  }

  /* Navigate to the saved route after authPopup routines
   * if no route is saved, honor eventSettings.layout.defaultScreen,
   * if no defaultScreen is set, use the very default 'promo-live' route.
   */
  private async authRedirectToSavedRoute(): Promise<void> {
    if (this.isInPopup) {
      if (this.authPopupTargetRoute) {
        try {
          await this.$router.push(this.authPopupTargetRoute);
        } catch {
          /* ignore */
        }
      } else {
        this.redirectToDefaultRoute();
      }

      await this.hideAuthPopup();

      return;
    }

    // Sign-up.vue is inline the page, not in the popup
    try {
      await this.$router.push(this.$route.params.redirect || { name: 'home' });
    } catch {
      /* ignore */
    }

  }

  private dispatchUserLoginPreprocessing(): void {
    const value: string = this.userLogin.trim();

    const isLikePhone: boolean = value.indexOf('@') < 0;

    if (isLikePhone) {
      this.preprocessUserLoginAsPhoneNumber(value);
    } else {
      this.validateUserLoginAsEmailAddress(value);
    }
  }

  private preprocessUserLoginAsPhoneNumber(value: string): void {

    // AW-1127 attach «+» to phones
    value = value.replace(/[^\d]/g, '');
    if (value) {
      value = '+' + value;
    } else {
      this.loginIsIncorrect = this.$t('authPage.screens.phoneEmail.errors.usePhoneOrEmail');
    }

    this.userLogin = value.trim();

  }

  private validateUserLoginAsEmailAddress(value: string): void {
    if (ValidationHelper.isValidEmail(value) === false) {
      this.loginIsIncorrect = this.$t('authPage.screens.phoneEmail.errors.usePhoneOrEmail');
    }
  }

  /* Sends login and password from the first step */
  private async signUp(): Promise<void> {

    this.dispatchUserLoginPreprocessing(); // Can result in isSendLoginButtonDisabled = true, thus stands before the check

    if (this.isSendLoginButtonDisabled) {
      return;
    }

    const payload: TSendLoginRequestParams = {
      login: this.userLogin,
      password: this.userPassword,
      platform: 'WEB',
      device_id: this.deviceId,
      // device_token: deviceToken,
    };

    await this.$store.dispatch('authStore/signUp', payload);

  }

  /* Checks the user-provided event code (ticket code, promocode, etc.) */
  private async checkEventCode(): Promise<void> {
    this.eventAccessCodeError = '';
    this.isEventAccessCodeSending = true;
    const response = await this.$store.dispatch('eventStore/activateCode', {
      event_id: this.$route.params.eventId,
      code: this.eventAccessCode
    });
    this.isEventAccessCodeSending = false;
    if (response && response.status === 202) {
      await this.authRedirectToSavedRoute();
      await this.$store.dispatch('_eventStore/refresh');
      await this.$store.dispatch('promoPageStore/refresh'); // AW-1622
    } else {
      this.eventAccessCodeError = this.$t('promoAccessCheck.accessNotGranted');
    }
  }

  private showEventAccessCodeScreen(): void {
    this.visibleAuthScreen = AuthScreenStep.EVENT_ACCESS_CODE_INPUT;
  }

  /* Some routines for keyup on login field.
   * I.e. clear errors if a key has been pressed in the field
   */
  private userLoginKeyupHandler(): void {
    this.loginIsIncorrect = '';
    this.$store.dispatch('authStore/clearAuthError');
  }

  /* Some routines for keyup on password field.
   * I.e. clear errors if a key has been pressed in the field
   */
  private userPasswordKeyupHandler(): void {
    this.$store.dispatch('authStore/clearAuthError');
  }

  private hideAuthPopup(): void {
    this.$store.dispatch('authStore/setAuthPopupVisible', {
      isAuthPopupVisible: false,
      targetRoute: null
    });

    this.$store.dispatch('authStore/setSignUpEnabled', false);
  }

  private togglePasswordVisibility(refName: string): void {
    this.$nextTick(() => {
      if (!this.$refs[refName]) {
        return;
      }

      switch (refName) {
        case 'passwordInput':
          this.passwordVisibilityEnterPassword = !this.passwordVisibilityEnterPassword;
          break;
        case 'newPasswordInput':
          this.passwordVisibilityNewPassword = !this.passwordVisibilityNewPassword;
          break;
        default:
          this.passwordVisibilityEnterPassword = !this.passwordVisibilityEnterPassword;
      }

    });
  }

  /* AW-1055 */
  private openSupportChat(event: Event): void {
    if (event && event.target) {
      const clickedElement: HTMLElement = event.target as HTMLElement;
      if (clickedElement.classList.contains('link')) {
        const helpCrunchInstance = HelpCrunchService._helpCrunch;
        if (!helpCrunchInstance) { // TODO: also check for instance.isInitialized and test it
          window.setTimeout(() => { this.openSupportChat(event); }, 1000);
          return;
        }
        helpCrunchInstance('openChat');
      }
    }
  }

  private showSignIn(): void {
    this.$store.dispatch('authStore/setSignUpEnabled', false);
  }

  private trackSignUpSuccessToGTM(): void {
    try {
      (window as any).dataLayer.push({
        event: 'registration-form-submit',
        formType: 'registration'
      });
    } catch {
      /* ignore */
    }
  }

  // AW-2721
  private saveAuthSourceForGTM(): void {
    if (!window || !window.localStorage) {
      return;
    }

    window.localStorage.setItem(AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME, AuthSources.SIGN_UP);
  }

}
