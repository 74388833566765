import {TEventSettings} from '@/_types/event.type';

const routeNamesToSettingsKeyMap: {[key: string]: string} = {
  'event-info': 'event_info',
  'promo-live': 'promo_live',
  news: 'news',
  'promo-program': 'promo_program',
  'promo-page-events-companies': 'promo_page_events_companies',
  'promo-page-calendar': 'promo_page_calendar',
  notes: 'notes_list',
  'text-chats': 'text_chats',
  'promo-contacts': 'promo_contacts',
  result: 'result',
};

export default class UtilsHelper {

  public static escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public static nl2br(str: string): string {
    return str.replace(/\n/g, '<br />');
  }

  public static flatJsonToNestedObj(json: any): any {
    const result: any = {};

    for (const path in json) {
      let cursor: any = result;
      const length = path.length;
      let property = '';
      let index = 0;

      while (index < length) {
        const char = path.charAt(index);
        let start = index;
        let end;

        if (char === '[') {
          start = index + 1;
          end = path.indexOf(']', start);
          cursor = cursor[property] = cursor[property] || [];
          property = path.slice(start, end);
          index = end + 1;
        } else {
          cursor = cursor[property] = cursor[property] || {};
          start = char === '.' ? index + 1 : index;
          const bracket = path.indexOf('[', start);
          const dot = path.indexOf('.', start);

          if (bracket < 0 && dot < 0) {
            end = index = length;
          } else if (bracket < 0) {
            end = index = dot;
          } else if (dot < 0) {
            end = index = bracket;
          } else {
            end = index = bracket < dot ? bracket : dot;
          }

          property = path.slice(start, end);
        }
      }

      cursor[property] = json[path];
    }

    return result[''];
  }

  public static routeNamesToSettingsKeys(key: string): string {
    return key ? routeNamesToSettingsKeyMap[key] : '';
  }

  public static getRouteNameByEventSettingsKey(eventSettingsKey: string): string {
    return Object.keys(routeNamesToSettingsKeyMap).find(key => routeNamesToSettingsKeyMap[key] === eventSettingsKey);
  }

  public static getDefaultRouteNameFromEventSettings(eventSettings: TEventSettings): string {
    const defaultDefaultScreen = 'promo-live'; // TODO: move into env, or some constant in an easily accessible place
    if (
      !eventSettings
      || !eventSettings.layout
      || !eventSettings.layout.defaultScreen
    ) {
      return defaultDefaultScreen;
    }

    return UtilsHelper.getRouteNameByEventSettingsKey(eventSettings.layout.defaultScreen) || defaultDefaultScreen;
  }

  public static getBrowserWithVersion(): string {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+\.\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return M.join(' ');
  }
}
