


import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TContact } from '@/_types/contact.type';

const DEFAULT_AVATAR = require('@/assets/images/no-avatar-60x60.svg');

@Component
export default class EwAvatar extends Vue {

  @Getter('contactsStore/isContactOnline') isContactOnline: (contactId: number) => boolean;

  @Prop({ type: Object })
  public readonly contact: TContact;

  @Prop({ type: String, default: '' })
  public src: string;

  @Prop({ type: Number, default: 48, })
  public width: number;

  @Prop({ type: Number, default: 0 })
  public readonly unreadCount: number;

  @Prop({ type: Boolean, default: false })
  public readonly disableOnlineBadge: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disableUnreadCount: boolean;

  public get isOnline(): boolean {
    if (!this.contact || this.disableOnlineBadge) {
      return false;
    }
    return this.isContactOnline(this.contact.id);
  }

  public get initialsSizeClass(): string {
    const w = this.width;
    let className: string;

    if (w > 0 && w <= 31) {
      className = 'av-size-12';
    } else if (w >= 32 && w <= 51) {
      className = 'av-size-14';
    } else {
      className = 'av-size-16';
    }

    return className;
  }

  public get avatarImageStyles(): any {
    const width = this.width;
    return {
      width: `${width}px`,
      height: `${width}px`,
      'min-width': `${width}px`,
      'min-height': `${width}px`,
      'max-width': `${width}px`,
      'max-height': `${width}px`,
      'background-color': this.backgroundColorValue,
      'background-image': this.backgroundImageValue
    };
  }

  public get backgroundColorValue(): string {

    if (!this.contactInitials) {
      return 'transparent';
    }

    const contactId = this.contact ? this.contact.id : 0;

    const name: string = ((this.contact ? this.contact.name : '') || '').trim();
    const surname: string = ((this.contact ? this.contact.surname : '') || '').trim();
    const sourceString = [name, surname].filter(x => x).join('') || contactId.toFixed(0);

    let hash = 0;
    for (let i = 0; i < sourceString.length; i++) {
      hash = sourceString.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;

    return 'hsl(' + h + ', 80%, 70%)';
  }

  public get backgroundImageValue(): string {
    if (!this.src && !this.contactPhotoUrl) {
      if (!this.contactInitials) {
        return 'url(' + DEFAULT_AVATAR + ')';
      }
      return '';
    }
    return 'url(' + (this.src || this.contactPhotoUrl) + ')';
  }

  public get contactPhotoUrl(): string {
    if (!this.contact || !this.contact.photo_url) {
      return '';
    }
    return this.contact.photo_url;
  }

  public get contactInitials(): string {
    if (!this.contact) {
      return '';
    }
    const name: string = (this.contact.name || '').trim().substr(0, 1);
    const surname: string = (this.contact.surname || '').trim().substr(0, 1);

    return [name, surname].join('');
  }

  public get isInitialsVisible(): boolean {
    if (!this.contact) {
      return false;
    }
    return !!(!this.src && !this.contactPhotoUrl && this.contactInitials);
  }

  public get style(): string {
    return `width: ${this.width}px; height: ${this.width}px;`;
  }

  public setPosition(val: number): number {
    const radius = this.width / 2;
    return radius + radius * Math.cos(90) - val;
  }

}
