<template>
  <div
    class="section navigation"
    ref="sectionNavigation"
  >
    <div class="section-body">
      <div class="nav-layout flex">
        <div class="part">
          <router-link
            class="brand"
            :to="getHomeURL"
            v-if="isHeaderLogoVisible"
          >
            <img
              alt="EventsWallet"
              src="@/assets/images/eventswallet-logo.svg"
              width="170"
            />
            <span
              class="home-site-intro"
              v-if="isHomeSiteIntroVisible"
            >
              {{ $t('homeCards.headerIntro') }}
            </span>
          </router-link>
        </div>
        <div class="part">
          <div class="flex top-menu">
            <div class="event-links">
              <div
                class="inline-block"
                v-if="isCreateEventButtonVisible"
              >
                <button
                  class="control control-button create-event-btn"
                  @click="createEvent()"
                >
                  {{ $t('navigation.createEvent') }}
                </button>
              </div>
            </div>
            <div class="top-languages">
              <language-picker />
            </div>
<!--            <div v-if="isAuthenticated" TODO: return when user avatar is needed-->
<!--                 class="user-avatar">-->
<!--            </div>-->
            <div
              v-if="isAuthenticated"
              @click="logout"
              :class="{
                 'show': $route.name === 'event-list'
              }"
              class="logout"
            />
<!--            <div v-if="!isAuthenticated && !isAuthLoading">-->
<!--              <router-link :to="authRoute">{{ $t('title.login') }}</router-link>-->
<!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import LanguagePicker from '@/_components/language-picker/language-picker';

const Navigation = Vue.extend({
  name: 'navigation',
  components: {
    LanguagePicker,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthLoading: 'authStore/isLoading',
    }),
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    isCreateEventButtonVisible() {
      return this.isAuthenticated && this.$route.name !== 'event-info' && this.$route.name !== 'event-create';
    },
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    authRoute() {
      if (this.$route.name === 'auth-page') {
        return { name: 'auth-page' };
      } else {
        return {
          name: 'auth-page',
          params: { redirect: this.$route.path },
        };
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    isHeaderLogoVisible() {
      return this.$route.meta ? !!this.$route.meta.showHeaderLogo : false;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    isHomeSiteIntroVisible() {
      return this.$route.meta ? !!this.$route.meta.showHomeSiteIntro : false;
    },

    /* Home URL is / + current locale */
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    getHomeURL() {
      return '/' + (this.$i18n.locale === this.$i18n.fallbackLocale ? '' : this.$i18n.locale);
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    async logout() {
      await this.$store.dispatch('authStore/authLogout');
      this.$router.push({ name: 'home' });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    createEvent() {
      this.$router.push({ name: 'event-create' });
    }
  },
});

export default Navigation;
</script>

<style lang="scss" scoped>
  .navigation {
    position: sticky;
    z-index: $z-index-navigation;
    width: 100%;
    top: 0;
    left: 0;
    color: $ew-black;
    background-color: $ew-white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.29);

    .nav-layout {
      justify-content: space-between;
      align-items: center;
    }

    .top-menu {
      font-weight: 300;
      font-size: 1.8rem;
      align-items: center;

      & > div,
      .event-links > div {
        margin: 0 10px 0 45px;
      }

      & > div.top-languages {
        @media (max-width: 640px) {
          margin-left: 0;
        }
      }

      .event-links {
        a {
          color: $ew-black;
        }

        a.router-link-exact-active {
          color: $palette-aqua;
          font-weight: 500;
        }
      }
    }

    .user-avatar {
      overflow: hidden;
      border-radius: 200px;
      width: 45px;
      height: 45px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("~@/assets/images/icon-noavatar.svg");
    }

  }

  .brand {
    display: flex;
    align-items: center;
  }

  .logout {
    width: 27px;
    height: 25px;
    margin-left: 16px !important;
    background-image: url("~@/assets/images/icon-logout-black.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      cursor: pointer;
    }
  }

  .show {
    opacity: 1;
  }

  .home-site-intro {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-left: 4.9rem;
    color: $ew-black;

    @media (max-width: 640px) {
      display: none;
    }
  }

  .create-event-btn {
    background: $ew-blue;
    border-radius: 33px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    color: $ew-white;
    height: 36px;
    min-width: 190px;
    padding: 0 30px;
    margin: 0 auto;
  }
</style>
