import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import {
  TabNames, TLastContacts,
  TSideBarRightStoreState
} from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import { TNote } from '@/_types/store/note.type';
import notesApi, { TGetNotesParams } from '@/_api/notes/notes.api';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TContact } from '@/_types/contact.type';

const getNotesRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getNotes.bind(notesApi));

const sideBarRightStore: Module<TSideBarRightStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isOpen: false,
    isSideBarRightComponentPresent: false,
    activeTabName: TabNames.NONE,
    selectedContactNotes: [],
    isUserSettingsOpen: false,
    isQRCodePopupVisible: false,
    contactId: null,
    lastContactsMap: {},
  },
  getters: {

    isOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isOpen;
    },

    isSideBarRightComponentPresent: (state: TSideBarRightStoreState): boolean => {
      return state.isSideBarRightComponentPresent;
    },

    activeTabName: (state: TSideBarRightStoreState): TabNames => {
      return state.activeTabName;
    },

    contactNotes: (state: TSideBarRightStoreState): TNote[] => {
      return state.selectedContactNotes;
    },

    isUserSettingsOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isUserSettingsOpen;
    },
    isQRCodePopupVisible: (state: TSideBarRightStoreState): boolean => {
      return state.isQRCodePopupVisible;
    },
    selectedMessageByContactId: (state: TSideBarRightStoreState): number => {
      return state.contactId;
    },
    lastContactsMap: (state: TSideBarRightStoreState): { [id: string]: TLastContacts } => {
      return state.lastContactsMap;

    },
    lastContactsCollection: (state: TSideBarRightStoreState): TLastContacts[] => {
      return Object.values(state.lastContactsMap).sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      });

    },
  },
  actions: {

    open: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('open');
    },

    close: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('close');
    },

    toggle: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('toggle');
    },

    setActiveTab: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, tabName: TabNames): void => {
      commit('setActiveTab', tabName);
    },

    clearActiveTab: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('setActiveTab', TabNames.NONE);
    },

    setComponentPresence: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setComponentPresence', value);
    },

    getContactNotes: async ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, params: TGetNotesParams): Promise<void> => {
      const {eventId, queryParams} = params;

      let data;
      try {
        data = await getNotesRequest.load({eventId, queryParams});
        commit('setNotes', data.List);
      } catch (error) {
        commit('setNotes', []);
      }
    },

    setIsUserSettingsOpen: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setIsUserSettingsOpen', value);
    },
    setIsQRCodePopupVisible: ({commit}: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setIsQRCodePopupVisible', value);
    },
    openMessageByContactId: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, contactId: number): void => {
      commit('setMessageByContactId', contactId);
    },
    clearMessageByContactId: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('setMessageByContactId', null);
    },
    setLastContacts: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, payload): void => {
      commit('setLastContacts', payload);
    },
  },
  mutations: {

    open(state: TSideBarRightStoreState): void {
      state.isOpen = true;
    },

    close(state: TSideBarRightStoreState): void {
      state.isOpen = false;
    },

    toggle(state: TSideBarRightStoreState): void {
      state.isOpen = !state.isOpen;
    },

    setActiveTab(state: TSideBarRightStoreState, tabName: TabNames): void {
      state.activeTabName = tabName;
    },

    setComponentPresence(state: TSideBarRightStoreState, value: boolean): void {
      state.isSideBarRightComponentPresent = value;
    },

    setNotes(state: TSideBarRightStoreState, value: TNote[]): void {
      state.selectedContactNotes = value;
    },

    setIsUserSettingsOpen(state: TSideBarRightStoreState, value: boolean): void {
      state.isUserSettingsOpen = value;
    },
    setIsQRCodePopupVisible(state: TSideBarRightStoreState, value: boolean): void {
      state.isQRCodePopupVisible = value;
    },
    setMessageByContactId(state: TSideBarRightStoreState, contactId: number): void {
      state.contactId = contactId;
    },
    setLastContacts(state: TSideBarRightStoreState, contact: TContact): void {
      const obj: any = Object.assign({}, {
        contact: contact,
        timestamp: new Date().getTime(),
      });
      state.lastContactsMap[contact.id] = obj;
      state.lastContactsMap = { ...state.lastContactsMap };
    },

  },
};

export default sideBarRightStore;
