import Vuex from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';

import authStore from '@/_store/auth.store';
import _userStore from '@/_store/user.store';
import meetingRoomsStore from '@/_modules/meeting-rooms/store/meeting-rooms.store';
import _eventStore from '@/_modules/events/store/event.store';
import promoPageStore from '@/_modules/promo/store/promo-page.store';
import statisticStore from '@/_modules/statistics/store/statistics.store';
import _messageStore from '@/_modules/promo/store/messages.store';
import editFormStore from '@/_store/edit-form.store';
import cabinetLobbyStore from '@/_modules/promo-cabinet/store/cabinet-lobby.store';
import promoProgramStore from '@/_modules/promo-program/store/promo-program.store';
import contactsStore from '@/_modules/contacts/store/contacts.store';
import chatStore from '@/_modules/chat/store/chat.store';
import sideBarRightStore from '@/_modules/promo/store/side-bar-right.store';
import sideBarLeftStore from '@/_modules/promo/store/side-bar-left.store';
import notificationsStore from '@/_modules/promo/store/notifications.store';
import meetingsStore from '@/_modules/meetings/store/meetings.store';
import mailSettingsStore from '@/_components/mail-settings/store/mail-settings.store';
import notesStore from '@/_modules/notes/store/notes.store';
import standaloneCompanyStore from '@/_modules/standalone-company/store/standalone-company.store';
import ewSharerStore from '@/_store/ew-sharer.store';

/* old */

// TODO: refactoring
// @ts-ignore
import languageStore from '@/store/modules/languageStore.js';
// @ts-ignore
import eventStore from '@/store/modules/eventStore.js';
// @ts-ignore
import ticketsStore from '@/store/modules/ticketsStore.js';
// @ts-ignore
import uploadImageStore from '@/store/modules/uploadImageStore.js';
// @ts-ignore
import locationStore from '@/store/modules/locationStore.js';
// @ts-ignore
import noteStore from '@/store/modules/noteStore.js';
// @ts-ignore
import promoStore from '@/store/modules/promoStore.js';
// @ts-ignore
import seatplanStore from '@/store/modules/seatplanStore.js';
// @ts-ignore
import uploadProductsStore from '@/store/modules/uploadProductsStore.js';
// @ts-ignore
import uploadPromopageFilesStore from '@/store/modules/uploadPromopageFilesStore.js';

const store = new Vuex.Store<TAppStoreState>({
  strict: true,
  modules: {
    authStore,
    _userStore,
    meetingRoomsStore,
    _eventStore,
    promoPageStore,
    statisticStore,
    chatStore,
    editFormStore,
    cabinetLobbyStore,
    promoProgramStore,
    contactsStore,
    sideBarLeftStore,
    sideBarRightStore,
    notificationsStore,
    _messageStore,
    meetingsStore,
    mailSettingsStore,
    notesStore,
    standaloneCompanyStore,
    ewSharerStore,

    /* old stores */
    languageStore,
    eventStore,
    ticketsStore,
    uploadImageStore,
    locationStore,
    noteStore,
    promoStore,
    seatplanStore,
    uploadProductsStore,
    uploadPromopageFilesStore,
  },
});

export default store;
