


import {Vue, Component, Prop} from 'vue-property-decorator';
import {TEvent} from '@/_types/event.type';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';

@Component({
  components: {EwButton}
})
export default class EventInfo extends Vue {

  @Prop()
  public readonly event: TEvent;

  public get eventLogo(): string {
    return this.event && this.event.org_logo;
  }

  public get eventImage(): string {
    return this.event && this.event.image;
  }

  public get eventName(): string {
    return this.event && this.event.title;
  }

  public get eventDescription(): string {
    return this.event && this.event.descr;
  }

  public get eventAgenda(): string {
    return this.event && this.event.agendas;
  }

  public get eventWebsite(): string {
    return this.event && this.event.url;
  }

  public get eventDateStart(): Date {
    return this.event && this.event.date_start;
  }

  public get eventDateEnd(): Date {
    return this.event && this.event.date_end;
  }

  public get eventDateStartStr(): string {

    if ((this.eventDateStart
      && this.eventDateEnd)
      && (this.eventDateStart.getFullYear() === this.eventDateEnd.getFullYear())) {
      return this.$moment(this.eventDateStart).format(DateTimeFormat.DAY_MONTH_TIME);
    } else {
      return this.$moment(this.eventDateStart).format(DateTimeFormat.DAY_MONTH_TIME_YEAR);
    }

  }

  public eventDateEndStr(): string {
    return this.$moment(this.eventDateEnd).format(DateTimeFormat.DAY_MONTH_TIME_YEAR);
  }

}
